<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='form.type == 3 ? "补贴套餐编辑" : form.type == 2 ? "专属套餐编辑" : "常规套餐编辑"'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='套餐名称' prop='name'>
          <a-input v-model='form.name' />
        </a-form-model-item>
        <a-form-model-item label='套餐类型' prop='typeId'>
          <a-select
            v-model="form.typeId"
            placeholder="请选择"
          >
            <a-select-option :value="item.id" v-for="(item, index) in typeList" :key="index">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='套餐封面' prop='cover'>
          <a-upload
            list-type='picture-card'
            :file-list="form.cover ? [{
              uid: '-1',
              name: 'cover',
              status: 'done',
              url: form.cover,
            }] : []"
            :customRequest='(event) => {handleCoverUpload(event)}'
            :remove='() => form.cover = ""'>
            <div v-if="!form.cover">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='套餐状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio value='online'>上架</a-radio>
            <a-radio value='offline'>下架</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='排序优先级' prop='priority' help='数字越大优先级又高'>
          <a-input-number v-model='form.priority' />
        </a-form-model-item>
        <a-divider />
        <a-row v-for="(item, index) in form.subList" :key="index">
          <a-col :span="6">
            <a-form-model-item label='商品名称' :labelCol="labelCol1">
              {{item.name}}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label='价格' :labelCol="labelCol1">
              <a-input v-model='item.subPrice' />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label='数量' :labelCol="labelCol1">
              <a-input-number v-model='item.subCount' :min="0" :max="item.maxQuantity === 0 || item.maxQuantity > 0 ? item.maxQuantity : 9999" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label='上限数量' :labelCol="labelCol1">
              <a-input-number v-model='item.maxQuantity' :min="0" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-form-model-item label='最低金额限制' :labelCol="labelCol1">
              <a-input v-model='form.minAmount' />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label='最高金额限制' :labelCol="labelCol1">
              <a-input v-model='form.maxAmount' />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label='合计商品价格'>
              {{totalPrice}}元
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
          <!-- <a-button @click='handleReset'>
            重置
          </a-button>
          <a-divider type='vertical' /> -->
          <a-button @click='handleCancel'>
            取消
          </a-button>
          <a-divider type='vertical' />
          <a-button :loading="loading" type='primary' @click='handleSubmit'>
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>

    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  getMeal,
  putMeal,
  queryMealCategories
} from '@/api/meal'
import { queryInsideProd } from '@/api/inside'
import client from '@/config/oss.config.js'

export default {
  name: 'MealEdit',
  data() {
    return {
      labelCol: { lg: { span: 3 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 8 }, sm: { span: 17 } },
      labelCol1: { lg: { span: 9 }, sm: { span: 9 } },
      wrapperCol1: { lg: { span: 8 }, sm: { span: 17 } },
      loading: false,
      typeList: [],//套餐类型
      form: {
        description: "",
        id: "",
        name: "",
        cover: "",
        type: 1,
        priority: "",
        status: "online",
        subList: [],
        typeId: undefined,
        minAmount: "",
        maxAmount: "",
      },
      rules: {
        name: [
          { required: true, message: '请填写套餐名称', trigger: 'change' },
        ],
        status: [
          { required: true, message: '请选择套餐状态', trigger: 'change' }
        ],
        priority: [{ required: true, message: '请填写排序优先级', trigger: 'change' }],
        minAmount: [{ required: true, message: '请填写最低金额限制', trigger: 'change' }],
        maxAmount: [{ required: true, message: '请填写最高金额限制', trigger: 'change' }],
        typeId: [{ required: true, message: '请选择套餐类型', trigger: 'change' }]
      }
    }
  },
  computed: {
    totalPrice() {
      let totalPrice = 0;
      for (const item of this.form.subList) {
        totalPrice += parseFloat(item.subCount) * parseFloat(item.subPrice);
      }
      return totalPrice;
    }
  },
  created() {
    this.handleInitial()
    this.$form.createForm(this);
  },
  activated() {
    this.handleInitial()
    this.$form.createForm(this)
    // debugger;
  },
  methods: {
    getInsideProd(keywords) {
      queryInsideProd({ keywords: keywords, pageMode: 0  }).then(result => {
        const subList = [];
        for (const item of result.records) {
          subList.push({
            depositoryProduction: item.id,
            name: item.name,
            subCount: 0,
            maxQuantity: "",
            subPrice: item.price
          })
        }
        this.form.subList = subList
      })
    },
    async handleCoverUpload(event) {
      this.loading = true
      let result = await client.put('meal/' + event.file.uid, event.file)
      this.form.cover = result.url
      this.loading = false
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      const { type } = this.$route.query
      if (type) {
        this.form.type = type
      }
      if (id) {
        getMeal(this.$route.query).then(result => {
          this.form = Object.assign({}, this.form, result)
        })
      } else {
        this.getInsideProd()
      }
      queryMealCategories({size: 1000,current:1}).then(result => {
        this.typeList = result.records;
      })
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          let subCount = 0;
          for (let index = 0; index < this.form.subList.length; index++) {
            const item = this.form.subList[index];
            item.priority = this.form.subList.length - index;
            subCount += item.subCount;
          }
          if (subCount > 0) {
            this.loading = true;
            putMeal(this.form).then(res => {
              this.$router.back()
            }).catch(() => {
              this.loading = false;
            })
          } else {
              this.$message.warning("至少拥有一个数量不为0的商品");
          }
        }
      })
    }

  }
}
</script>
